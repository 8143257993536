import React from "react"
import { replaceSpacing } from "../../utils/functions"
import Item from "../../components/item"
import { animationSlideUp, animationSlideRight, animationSlideLeft, animationFade, animationZoomIn } from "../../utils/scroll-animation"
import Hints from "../../common/hints/hints"

export default function Services(props) {
  const { content, styles } = props
  return (
    <section
      style={{backgroundColor: content?.backgroundColor ? content.backgroundColor.hex : "",}}
      className={styles.content}
    >
      <div className="default-container">
        <div className={styles.contentContainer}>
            <div className={styles.titleWrapper}>
              <h2 {...animationSlideRight()}>
                {replaceSpacing(content?.title)}
              </h2>
              <Hints hints={content?.hints} isAnimation={content?.isHintsAnimation}
                typingBackground={content?.backgroundColor ? content.backgroundColor.hex: "#673895"}
              />
            </div>
            <div className={styles.contentWrapper} {...animationFade()}>
              <div className={styles.features}>
                {content?.features.map((feature, index) => (
                  <Item key={index} props={feature} />
                ))}
              </div>
            </div>
        </div>
      </div>
    </section>
  )
}
