import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Seo from "../components/seo"
import Hero from "../sections/hero/hero"
import ClientExp from "../sections/clients_exp/clients_exp"
import Features from "../sections/features/features"
import Services from "../sections/services/services"
import CallToAction from "../sections/call_to_action/call_to_action"
import Layout from "../components/Layout"
import About from "../sections/about/about"
import * as hero_styles from "../sections/hero/hero_index.module.scss"
import * as client_exp_styles from "../sections/clients_exp/clients_exp.module.scss"
import * as feature_inline_style from "../sections/features/features_inline_4.module.scss"
import * as icon_background from "../sections/features/icon_background.module.scss"
import * as square_call from "../sections/call_to_action/action_square.module.scss"
import * as services_styles from "../sections/services/services.module.scss"
import * as about_styles from "../sections/about/about.module.scss"
import BusinessOwnersData from "../utils/businessOwners-queries"
import { getSeo } from "../utils/seo"

export default function BusinessOwners(props) {
  const { hero, features, about, help, approach, testimonial, process } =
    BusinessOwnersData()
  return (
    <Layout navBG={hero?.backgroundColor ? hero.backgroundColor.hex : ""}>
      {/* <Seo
        title={business.title}
        keywrods={business.keywrods}
        description={business.description}
      /> */}
      <Hero
        hero={hero}
        styles={hero_styles}
        nextSection={features.slug?.current}
      />
      <Features features={features} styles={feature_inline_style} />
      <About content={about} styles={about_styles} />
      <Services content={help} styles={services_styles} />
      <Features features={approach} styles={icon_background} />
      <ClientExp content={testimonial} styles={client_exp_styles} />
      <CallToAction call={process} styles={square_call} arrow={true} />
    </Layout>
  )
}

export const Head = () => {
  const { seo } = BusinessOwnersData()
  return getSeo(seo)
}
