import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Social from "../../components/social/Social"
import ArrowCorner from "../../../assets/arrow_corner.svg"
import { replaceSpacing } from "../../utils/functions"
import { useState } from "react"
import {
  animationSlideUp,
  animationSlideRight,
  animationSlideLeft,
  animationFade,
  animationZoomIn,
} from "../../utils/scroll-animation"

export default function CallToAction(props) {
  const { call, styles, social, arrow } = props
  const {
    book,
    setBook,
    setRequestMockupPopup,
    isRequestMockup,
    getInTouch,
    setGetInTouch,
  } = props

  const getAction = () => {
    switch (call.target) {
      case "get_a_quote":
        setBook(true)
        break
      case "request_mockup":
        setRequestMockupPopup(true)
        break
      case "get_in_touch":
        setGetInTouch(true)
        break
      default:
        setBook(true)
        break
    }
  }
  return (
    <section
      style={{
        backgroundColor: call?.backgroundColor ? call.backgroundColor.hex : "",
      }}
      className={styles.header}
    >
      <div className="default-container">
        <div className={styles.callContainer}>
          <div className={styles.contentWrapper}>
            <div className={styles.action} {...animationFade()}>
              <h1>
                {arrow && (
                  <span
                    style={{
                      fill:
                        call?.backgroundColor?.hex === "#663695"
                          ? "#271B4F"
                          : "#663695",
                    }}
                    className={styles.arrowTop}
                  >
                    <ArrowCorner />
                  </span>
                )}
                {replaceSpacing(call.title)}
                {arrow && (
                  <span
                    style={{
                      fill:
                        call?.backgroundColor?.hex === "#663695"
                          ? "#271B4F"
                          : "#663695",
                    }}
                    className={styles.arrowBottom}
                  >
                    <ArrowCorner />
                  </span>
                )}
              </h1>
              <p className={styles.description}>{call.description}</p>
              {call.button && (
                <button onClick={() => getAction()}>{call.button}</button>
              )}
            </div>
          </div>
        </div>
      </div>
      {call?.image && (
        <div className={styles.imageContent}>
          <img src={call?.image?.asset.url} />
        </div>
      )}
    </section>
  )
}
