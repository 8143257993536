import { graphql, useStaticQuery } from "gatsby"

export default function BusinessOwnersData() {
  return useStaticQuery(graphql`
    query queryOwner {
      seo: sanitySeo(slug: { current: { eq: "for-bussines-owners" } }) {
        meta_title
        meta_description
        keywords
        meta_image {
          asset {
            url
          }
        }
        alt_tag
      }
      hero: sanityHero(slug: { current: { eq: "your-tech-your-way" } }) {
        title
        hints {
          parts
        }
        svgUpload
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
      features: sanityFeatures(
        slug: { current: { eq: "why-integrate-tech-into-your-business" } }
      ) {
        id
        slug {
          current
        }
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      about: sanityAbout(
        slug: { current: { eq: "building-tech-with-devwiz" } }
      ) {
        description
        title
        hints {
          parts
        }
        backgroundColor {
          hex
        }
        image {
          asset {
            url
          }
        }
        isHintsAnimation
      }
      help: sanityFeatures(slug: { current: { eq: "how-we-can-help" } }) {
        id
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      approach: sanityFeatures(
        slug: { current: { eq: "devwiz-approach-end-to-end" } }
      ) {
        id
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          backgroundColor {
            hex
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      testimonial: sanityTestimonials(slug: { current: { eq: "dark" } }) {
        title
        testimonials {
          author {
            name
            position
            image {
              asset {
                url
              }
            }
          }
          text
        }
        shadowBackgroundColor {
          hex
        }
        cardBackgroundColor {
          hex
        }
        backgroundColor {
          hex
        }
      }
      process: sanityCallAction(
        slug: {
          current: { eq: "get-the-ball-rolling-with-a-strategy-session" }
        }
      ) {
        title
        button
        image {
          asset {
            url
          }
        }
        description
        background {
          asset {
            url
          }
        }
        backgroundColor {
          hex
        }
        target
      }
    }
  `)
}
