// extracted by mini-css-extract-plugin
export var aboutContainer = "icon_background-module--about-container--2DGAy";
export var aboutContent = "icon_background-module--about-content--33IBF";
export var appSlider = "icon_background-module--app-slider--3cBju";
export var contentWrapper = "icon_background-module--content-wrapper--3sAwf";
export var defaultContainer = "icon_background-module--default-container--Zjodh";
export var featureSlider = "icon_background-module--feature-slider--2ViXk";
export var features = "icon_background-module--features--3UtzC";
export var featuresItem = "icon_background-module--features-item--2Bjjc";
export var infoBoxImage = "icon_background-module--info-box-image--2gquY";
export var innerPagesSlider = "icon_background-module--inner-pages-slider--3RPWz";
export var leftAppSlide = "icon_background-module--left-app-slide--Mc1Pb";
export var logoSlider = "icon_background-module--logo-slider--1Nr-a";
export var numbers = "icon_background-module--numbers--26wWQ";
export var phoneAppSelect = "icon_background-module--phone-app-select--3r-uw";
export var phoneCaseSelect = "icon_background-module--phone-case-select--45jgg";
export var rightAppSlide = "icon_background-module--right-app-slide--2lRvG";
export var slickActive = "icon_background-module--slick-active--14OJL";
export var slickArrow = "icon_background-module--slick-arrow--2ls1B";
export var slickCenter = "icon_background-module--slick-center--1T2oE";
export var slickDots = "icon_background-module--slick-dots--1R1Sa";
export var slickNext = "icon_background-module--slick-next--2uKiP";
export var slickPrev = "icon_background-module--slick-prev--3VpdS";
export var slickSlide = "icon_background-module--slick-slide--1xrY3";
export var slickSlider = "icon_background-module--slick-slider--16sLJ";
export var slickTrack = "icon_background-module--slick-track--1GYRD";
export var solvingSlider = "icon_background-module--solving-slider--1zz7v";
export var subtitle = "icon_background-module--subtitle--3xGsj";
export var title = "icon_background-module--title--1_0p0";
export var titleWrapper = "icon_background-module--title-wrapper--1kDr5";