import React from "react"
import { replaceSpacing } from "../utils/functions"

export default function Item(props) {
  const feature = props.props

  return (
    <div className="features">
      <img src={feature?.icon.asset.url} />
      <div className="text">
        <h1>{replaceSpacing(feature?.title)}</h1>
        <p>{replaceSpacing(feature?.info)}</p>
      </div>
    </div>
  )
}
