// extracted by mini-css-extract-plugin
export var aboutContainer = "features_inline_4-module--about-container--axLgr";
export var aboutContent = "features_inline_4-module--about-content--3-76Z";
export var appSlider = "features_inline_4-module--app-slider--FoT_a";
export var contentWrapper = "features_inline_4-module--content-wrapper--1Zk7A";
export var defaultContainer = "features_inline_4-module--default-container--3nGtg";
export var featureSlider = "features_inline_4-module--feature-slider--17sCq";
export var features = "features_inline_4-module--features--1N5s3";
export var featuresItem = "features_inline_4-module--features-item--9pm9_";
export var infoBoxImage = "features_inline_4-module--info-box-image--324YK";
export var innerPagesSlider = "features_inline_4-module--inner-pages-slider--1sQOb";
export var leftAppSlide = "features_inline_4-module--left-app-slide--1o5og";
export var logoSlider = "features_inline_4-module--logo-slider--1k9r2";
export var numbers = "features_inline_4-module--numbers--1qCcM";
export var phoneAppSelect = "features_inline_4-module--phone-app-select--CnA9c";
export var phoneCaseSelect = "features_inline_4-module--phone-case-select--3nYcy";
export var rightAppSlide = "features_inline_4-module--right-app-slide--2hShs";
export var slickActive = "features_inline_4-module--slick-active--3hjbB";
export var slickArrow = "features_inline_4-module--slick-arrow--34XvS";
export var slickCenter = "features_inline_4-module--slick-center--iFxgD";
export var slickDots = "features_inline_4-module--slick-dots--1N_e7";
export var slickNext = "features_inline_4-module--slick-next--2Gphd";
export var slickPrev = "features_inline_4-module--slick-prev--1ohiJ";
export var slickSlide = "features_inline_4-module--slick-slide--3ApyW";
export var slickSlider = "features_inline_4-module--slick-slider--omHhG";
export var slickTrack = "features_inline_4-module--slick-track--2F-tJ";
export var solvingSlider = "features_inline_4-module--solving-slider--FuVEj";
export var title = "features_inline_4-module--title--kFvCb";
export var titleWrapper = "features_inline_4-module--title-wrapper--fiDeU";