// extracted by mini-css-extract-plugin
export var appSlider = "services-module--app-slider--2GAFx";
export var content = "services-module--content--13DN3";
export var contentContainer = "services-module--content-container--1AKqu";
export var contentWrapper = "services-module--content-wrapper--tv-QV";
export var defaultContainer = "services-module--default-container--1XCN7";
export var featureSlider = "services-module--feature-slider--2Gjpx";
export var features = "services-module--features--2QWRU";
export var innerPagesSlider = "services-module--inner-pages-slider--2Ncse";
export var leftAppSlide = "services-module--left-app-slide--1msGI";
export var logoSlider = "services-module--logo-slider--dPkxL";
export var phoneAppSelect = "services-module--phone-app-select--3SJdY";
export var phoneCaseSelect = "services-module--phone-case-select--3lfjH";
export var rightAppSlide = "services-module--right-app-slide--cDqQN";
export var slickActive = "services-module--slick-active--27TJY";
export var slickArrow = "services-module--slick-arrow--3_gjT";
export var slickCenter = "services-module--slick-center--1nu2A";
export var slickDots = "services-module--slick-dots--1F7NZ";
export var slickNext = "services-module--slick-next--1xCyL";
export var slickPrev = "services-module--slick-prev--l8x6u";
export var slickSlide = "services-module--slick-slide--2TNMk";
export var slickSlider = "services-module--slick-slider--2U0jR";
export var slickTrack = "services-module--slick-track--2pmcl";
export var solvingSlider = "services-module--solving-slider--lL_20";
export var titleWrapper = "services-module--title-wrapper--3ODW3";